.refund-main {
  padding: 50px 40px 30px;
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  z-index: 1;
  margin: 0px 5% 60px 5%;
  position: absolute;
  left: 0;
  right: 0;

  .refund-header {
    text-align: center;
    padding-bottom: 20px;
  }

  .list-details {
    font-size: 16px;
    padding-left: 16px;
    margin-top: 20px;

    li {
      padding-bottom: 10px;
    }
  }

  h4 {
    margin-bottom: 0;
  }

  .btn-secondary {
    background: #efefef;
    border-radius: 7px;
    color: #1c1c1c;
    border-color: #efefef;
    width: 190px;
    height: 52px;
    margin-left: 20px;
  }

  .btn-primary {
    background: #ffc235;
    border-radius: 7px;
    border-color: #efefef;
    width: 190px;
    height: 52px;
  }

  .btn-action {
    width: 80px;
    height: 40px;
  }

  .btn-next {
    margin-left: 20px;
    margin-top: 15px;
  }

  .btn-back {
    margin-left: 0px;
    margin-top: 15px;
  }

  .acknowledgment-inputs,
  .acknowledgment-inputs:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
  }
  .save-img {
    width: 200px;
    border-radius: 20px;
  }
}
