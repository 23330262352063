.Navigation-Menu {
    .division {
        height: 2px;
        border: 1px solid #ECECEC;
    }
    .user-Info-Section {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        h6 {
            font-weight: 600;
            letter-spacing: 1px;
        }
        p {
            color: #BFC3C9;
            font-weight: 400;
        }
    }
    .user-Info-Verification {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F0F9E4;
        border-radius: 19px;
        width: 100%;
        height: 38px;
        img {
            margin-right: 8px;
        }
        span {
            color: #14AA37;
        }
    }
    .user-nav-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .user-nav-links-body {
            display: flex;
            flex-direction: column;
            justify-content: start;
            gap : 30px;
            margin-top: 20px;
            a {
                text-decoration: none;
                padding-left: 6%;
                color: black;
                font-weight: 400;
                img {
                    margin-right: 10px;
                }
            }
            .nav-active {
                height: 40px;
                left: 20px;
                background: #FFB730;
                border-radius: 10px;
                color: white;
                display: flex;
                align-items: center;
            }
        }
    }
    .nav-footer {
        position: fixed;
        bottom: 0;
        a {
            text-decoration: none;
            padding-left: 6%;
            display: flex;
            color: black;
            font-weight: 400;
            img {
                margin-right: 10px;
            }
            span {
                white-space: nowrap; 
            }
        }
    }
}